import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Advanced photomasks`}</h4>
    <p>{`Photomasks are transparent plates containing a circuit pattern to be fabricated in a chip. Photolithography equipment produces light that passes through this pattern so that the photomask's pattern is transferred to the chip.  Each photomask is specific to one chip design. Photomasks are produced by captive mask shops (businesses within large semiconductor manufacturing firms) or merchant mask shops (which sell to semiconductor manufacturers). Japan, the United States, Taiwan, and South Korea lead production of leading-edge photomasks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      